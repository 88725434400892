
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
import { IModel, IModelCreate } from "@/interfaces";
import { readModels } from "@/store/model/getters";
import { dispatchGetModels, dispatchCreateModel, dispatchDeleteModel } from "@/store/model/actions";
import { dispatchGetDatasets } from "@/store/dataset/actions";

@Component
export default class UserProfile extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }
  public async beforeRouteUpdate(to, from, next) {
    await dispatchGetModels(this.$store, {
      id: +to.params.workspaceid,
    });
    await dispatchGetDatasets(this.$store, {
      id: +to.params.workspaceid,
    });
    next();
  }
  public async mounted() {
    await dispatchGetModels(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
  }
  get models() {
    return readModels(this.$store);
  }
}
