
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
import { IModel, IModelCreate } from "@/interfaces";
import { readModels, readModel } from "@/store/model/getters";
import { dispatchGetModels, dispatchCreateModel, dispatchDeleteModel } from "@/store/model/actions";
import { readGenerativeModel } from "@/store/generative_model/getters";
import { dispatchGetGenerativeModels } from "@/store/generative_model/actions";

@Component
export default class UserProfile extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }
  public async beforeRouteUpdate(to, from, next) {
    await dispatchGetModels(this.$store, {
      id: +to.params.workspaceid,
    });
    next();
  }
  public async mounted() {
    if (this.isClassification) {
      await dispatchGetModels(this.$store, {
        id: +this.$router.currentRoute.params.workspaceid,
      });
    } else {
      await dispatchGetGenerativeModels(this.$store, {
        id: +this.$router.currentRoute.params.workspaceid,
      });
    }

  }
  get model() {
    const model = this.isClassification ? readModel(this.$store)(+this.$router.currentRoute.params.id) : readGenerativeModel(this.$store)(+this.$router.currentRoute.params.id);
    return model;
  }

  get isClassification() {
    return this.$router.currentRoute.path.includes("classification");
  }

  get models() {
    return readModels(this.$store);
  }
}
