
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
import { IModel, IModelCreate } from "@/interfaces";
import { readModels } from "@/store/model/getters";
import { dispatchGetModels, dispatchCreateModel, dispatchDeleteModel } from "@/store/model/actions";
import { dispatchGetWorkspace } from "@/store/main/actions";

@Component
export default class Workspaces extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }

  public async mounted() {
    await dispatchGetModels(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }
  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$router.currentRoute.params.workspaceid === "default") {
        let toPath = to.path;
        toPath = toPath.replace("default", vm.userProfile.workspaces[0]!.workspace_id);
        vm.$router.push({
          path: toPath,
        });
      }
    });
  }
  public async beforeRouteUpdate(to, from, next) {
    await dispatchGetModels(this.$store, {
      id: +to.params.workspaceid,
    });
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(to.params.workspaceid, 10),
    });
    next();
  }
}
