
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
import { IDataset, IDatasetCreate } from "@/interfaces";
import { readDatasets, readDataset } from "@/store/dataset/getters";
import {
  dispatchGetDatasets,
  dispatchDeleteDataset,
} from "@/store/dataset/actions";

@Component
export default class UserProfile extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }
  public async beforeRouteUpdate(to, from, next) {
    await dispatchGetDatasets(this.$store, {
      id: +to.params.workspaceid,
    });
    next();
  }
  public async mounted() {
    await dispatchGetDatasets(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
  }
  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  get datasets() {
    return readDatasets(this.$store);
  }
}
