
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { readUserProfile, readActiveWorkspace } from "@/store/main/getters";
// import { IDataset, IDatasetCreate } from "@/interfaces";
import { readDatasets } from "@/store/dataset/getters";
import { dispatchGetDatasets } from "@/store/dataset/actions";

@Component
export default class Analytic extends Vue {
  get userProfile() {
    return readUserProfile(this.$store);
  }
  public async beforeRouteUpdate(to, from, next) {
    console.log("here", to)
    await dispatchGetDatasets(this.$store, {
      id: +to.params.workspaceid,
    });
    next();
  }

  public async mounted() {
    await dispatchGetDatasets(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
  }

  get datasets() {
    return readDatasets(this.$store);
  }
}
